import React from "react";
import { useDraggable } from "@dnd-kit/core";
import Assets from "../../assets/images";
import { useSelector } from "react-redux";

const DraggableItem = ({ item, editingMode, openCustomSideBar }) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: item.id.toString(),
    data: { openCustomSideBar },
  });

  const universalStyles = useSelector(
    (state) => state.bodyStyling.universalStyles
  );

  const whiteImages = {
    2: Assets.ConnectIcon,
    3: Assets.GivingIcon,
    8: Assets.EventIcon,
    column: Assets.ColumnIcon,
    row: Assets.RowIcon,
    1: Assets.PlayerIcon,
    4: Assets.MapIcon,
    5: Assets.ServeIcon,
    6: Assets.OurCodeIcon,
    7: Assets.GroupIcon,
    add_custom: Assets.CustomIcon,
    9: Assets.PlayerIcon,
    13: Assets.GivingIcon,
    14: Assets.GivingIcon,
    15: Assets.GivingIcon,
    16: Assets.GivingIcon,
    17: Assets.GivingIcon,
    18: Assets.GivingIcon,
    19: Assets.GivingIcon,
    20: Assets.GroupIcon,
    // Teams widget
    21: Assets.ConnectIcon,
    22: Assets.ConnectIcon,
    23: Assets.ConnectIcon,
    24: Assets.ConnectIcon,
    25: Assets.ConnectIcon,
    // Serve Widget
    26: Assets.ConnectIcon,
    27:Assets.GivingIcon
  };

  const blackImages = {
    2: Assets.BlackConnectIcon,
    3: Assets.BlackGivingIcon,
    8: Assets.BlackEventIcon,
    column: Assets.BlackColumnIcon,
    row: Assets.BlackRowIcon,
    1: Assets.BlackPlayerIcon,
    4: Assets.BlackMapIcon,
    5: Assets.BlackServeIcon,
    6: Assets.BlackOurCodeIcon,
    7: Assets.BlackGroupIcon,
    add_custom: Assets.BlackCustomIcon,
    9: Assets.BlackPlayerIcon,
    13: Assets.BlackGivingIcon,
    14: Assets.BlackGivingIcon,
    15: Assets.BlackGivingIcon,
    16: Assets.BlackGivingIcon,
    17: Assets.BlackGivingIcon,
    18: Assets.BlackGivingIcon,
    19: Assets.BlackGivingIcon,
    20: Assets.BlackGroupIcon,
    // Teams widget
    21: Assets.BlackConnectIcon,
    22: Assets.BlackConnectIcon,
    23: Assets.BlackConnectIcon,
    24: Assets.BlackConnectIcon,
    25: Assets.BlackConnectIcon,
    // Serve Widget
    26: Assets.BlackConnectIcon,
    27:Assets.BlackGivingIcon
  };

  const widgetNames = {
    2: "Connect",
    3: "Giving",
    8: "Event List",
    column: "Column",
    row: "Row",
    1: "Player",
    4: "Map",
    5: "Serve",
    6: "Our Code",
    7: "Groups",
    add_custom: "Custom Widgets",
    9: "Player",
    13: "Advance Giving",
    14: "Giving Goal",
    15: "Annual Budget",
    16: "Budget Resource",
    17: "Giving Statement",
    18: "Have Given",
    19: "Giving Graph",
    20: "Group List",
    // Teams widget
    21: "Songs",
    22: "Calendar",
    23: "Team Details",
    24: "Team Chat",
    25: "Team Member",
    26: "Teams",
    27:'Guest Giving'
  };

  return (
    <div
      ref={setNodeRef}
      className={`themelayoutinnerbox roundcorner d-flex flex-column align-items-center${
        editingMode.widgtId == item.id ? "active" : ""
      } draggable-div`}
      {...attributes}
      {...listeners}
      style={{
        // border: "2px solid #8c8c8d",
        border: `2px solid ${
          universalStyles && universalStyles.theme == "dark"
            ? "#4A4A4A"
            : "#8c8c8d"
        }`,
        height: "unset",
        padding: 10,
        paddingBottom: 6,
      }}
    >
      <img
        style={{
          border: "unset",
          marginBottom: 6,
        }}
        src={
          universalStyles && universalStyles.theme !== "dark"
            ? whiteImages[item.id]
            : blackImages[item.id]
        }
        alt="Widget Icon"
      />
      <span
        style={{
          color:
            universalStyles && universalStyles.theme == "dark"
              ? "#fff"
              : "#000",
          fontWeight: "500",
          // universalStyles && universalStyles.theme == "dark" ? "400" : "600",
        }}
      >
        {widgetNames[item.id]}
      </span>
      {/* {item.id === "add_custom" ? (
        <span>
          Add<br></br>Custom Widget
        </span>
      ) : (
        <span>
          {item.id == 1
            ? "Player"
            : item.id === 2
            ? "Connect"
            : item.id === 3
            ? "Giving"
            : item.id === 4
            ? "Map"
            : item.id === 5
            ? "Serve"
            : item.id === 6
            ? "Our Code"
            : item.id === 7
            ? "Groups"
            : item.id === 8
            ? "Event List"
            : item.id == 9
            ? "Watch"
            : item.id == 10
            ? "Chat"
            : item.id == 11
            ? "Notes"
            : item.id == 12
            ? "Sermon"
            : item.id == "column"
            ? "Column"
            : item.id == "row"
            ? "Row"
            : null}
        </span>
      )} */}
    </div>
  );
};

export default DraggableItem;
